@font-face {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 300;
  src: local("Kepler Std"),
    url("https://fonts.cdnfonts.com/s/27351/KeplerStdLight.woff") format("woff");
}
@font-face {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 400;
  src: local("Kepler Std"),
    url("https://fonts.cdnfonts.com/s/27351/KeplerStdRegular.woff")
      format("woff");
}
@font-face {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 500;
  src: local("Kepler Std"),
    url("https://fonts.cdnfonts.com/s/27351/KeplerStdMedium.woff")
      format("woff");
}
@font-face {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 600;
  src: local("Kepler Std"),
    url("https://fonts.cdnfonts.com/s/27351/KeplerStdSemibold.woff")
      format("woff");
}
@font-face {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 700;
  src: local("Kepler Std"),
    url("https://fonts.cdnfonts.com/s/27351/KeplerStdBold.woff") format("woff");
}
