@use "../utils" as *;

/*----------------------------------------*/
/*  03. MEAN MENU CSS START
/*----------------------------------------*/

/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}
.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}
.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}
.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.mean-container a.meanmenu-reveal {
  display: none !important;
}
.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: var(--tp-common-white);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
  }
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: var(--tp-common-white);
  line-height: 30px;
  top: 0;
  font-weight: 400;
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}
.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  // background: #070337;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  // background: #070337;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li.active {
  & > a {
    text-decoration: underline;
  }
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: none;
  line-height: 30px;
  font-size: 16px;
  color: #000;
  font-weight: var(--tp-fw-normal);
  font-family: var(--tp-ff-poppins);
}

.mean-container .mean-nav ul li li {
  margin-bottom: 10px;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 0 10px;
  text-shadow: none !important;
  visibility: visible;
  font-size: 14px;
}

.mean-container .mean-nav ul li li a.active {
  text-decoration: underline;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 10px 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 10px 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 10px 25%;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
  font-size: 14px;
  cursor: pointer;
  color: #000;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

.mean-container .mean-nav li.has-dropdown .submenu {
  padding-top: 40px;
}
