@use "../utils" as *;

/*----------------------------------------*/
/*  SINGLE PAGE CSS
/*----------------------------------------*/

.page__element {
  position: relative;

  &:not(.default-text) p,
  &:not(.default-text) li {
    font-size: 21px;
    line-height: 34px;
    color: black;
    font-weight: var(--tp-fw-light);
  }

  & p:last-child {
    margin-bottom: 0;
  }

  &.single-image {
    &.full-height {
      @media #{$min-lg} {
        min-height: 100vh;
      }

      & .postbox__block-img {
        & img {
          @media #{$min-lg} {
            position: absolute;
          }

          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    &.h-400 {
      & .postbox__block-img {
        @media #{$min-xs} {
          height: 400px;
        }
      }
    }

    & img {
      object-position: 50% 50%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  } // single-image

  &.text-editor {
    // @media #{$min-lg} {
    //   width: 75%;
    //   margin: 0 auto;
    // }

    // & p {
    //   font-size: 21px;
    //   line-height: 34px;
    //   color: black;
    //   font-weight: var(--tp-fw-light);
    // }

    // & p:last-child {
    //   margin-bottom: 0;
    // }

    &.max-width-75 {
      @media #{$min-lg} {
        width: 75%;
        margin: 0 auto;
      }
    }
  } // text-editor

  &.text-on-image {
    & .container-fluid {
      padding-right: 0;
      padding-left: 0;

      & .row {
        --bs-gutter-x: 0;
      }
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 58px;
      margin-bottom: 0;
      font-weight: 300;
      color: #fff;

      @media #{$lg} {
        font-size: 52px;
      }

      @media #{$md} {
        font-size: 43px;
      }
      @media #{$xs} {
        font-size: 36px;
      }
    }

    & p {
      font-size: 32px;
      color: #fff;

      @media #{$xs} {
        font-size: 24px;
      }
    }

    & p:last-child {
      margin-bottom: 0;
    }

    & .breadcrumb__area {
      @media #{$xs} {
        padding: 120px 0;
      }
    }

    & .breadcrumb__content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media #{$min-lg} {
        width: 75%;
        margin: 0 auto;
      }
    }
  } // text-on-image

  &.image-box {
    & .image-box-text {
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        font-size: 28px;
        margin-bottom: 25px;
      }
      & p {
        font-size: 18px;
        font-weight: var(--tp-fw-light);
        color: #000;
      }
    }

    &.gap-30 {
      .col-12 {
        @media #{$min-lg} {
          padding-right: calc(var(--bs-gutter-x) * 0.8);
          padding-left: calc(var(--bs-gutter-x) * 0.8);
        }
      }
    }
  } // image-box

  &.text-box {
    &:not(.default-text) .text-box-inner {
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        margin-top: 10px;
        margin-bottom: 25px;
        font-size: 38px;
        line-height: 46px;
        font-weight: var(--tp-fw-light);

        @media #{$xs} {
          font-size: 32px;
          line-height: 40px;
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }

    & .text-box-inner {
      @media #{$min-lg} {
        padding: 0 15px;
      }

      margin-bottom: 25px;
    }

    & li {
      @media #{$xs} {
        font-size: 16px !important;
        line-height: 28px !important;
      }
    }

    & ul {
      margin-bottom: 20px;
    }

    &.text-lg {
      p {
        font-size: 21px;
        line-height: 34px;
      }
    }

    &.text-padding {
      p {
        @media #{$min-lg} {
          padding-left: 15%;
        }
      }
    }
  } // text-box

  &.newsletter-form {
    background-color: #e2ccbf;

    & .tp-contact-form {
      top: unset;
      padding-left: unset;
      padding-right: unset;
      margin-bottom: unset;
      border-bottom: unset;

      @media #{$min-lg} {
        width: 40%;
        margin: 0 auto;
      }

      & input {
        height: 64px;
        padding: 20px;
        font-family: var(--tp-ff-p);
        font-size: var(--tp-fz-p);
        font-weight: var(--tp-fw-light);
      }

      & .newsletter-title {
        & p {
          font-size: 26px;
          margin: 0 0 1rem 0;
          padding: 0;
          text-align: inherit;
          text-align: center;

          @media #{$max-lg} {
            font-size: 24px !important;
          }
        }
      }

      & p {
        font-size: 18px;
        text-align: center;
      }

      & form {
        @media #{$min-lg} {
          padding: 16px 0;
          width: 75%;
          margin: 0 auto;
        }

        & button {
          flex-shrink: 0;
          padding: 20px 30px;
          height: fit-content;
        }
      }
    }

    & .form-message {
      position: absolute;
      bottom: 40px;

      @media #{$max-lg} {
        bottom: 55px;
      }

      &.form-error {
        color: #ce3443;
      }

      &.form-success {
        color: #1b714a;
      }
    }
  } // newsletter-form
} // page-element

.homepage {
  & .page__element {
    &.text-box h2,
    &.text-editor h2 {
      @media #{$max-lg} {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}

.slider-one {
  min-height: 100px;

  & .slider-one-area {
    position: relative;
    box-sizing: border-box;
    user-select: none;
    z-index: 0;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    height: 100vh;

    @media #{$max-lg} {
      height: 50vh;
    }

    & .slider-one-acive {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      position: relative;
      flex-grow: 1;
      flex-shrink: 1;
      z-index: 0;
      box-sizing: content-box;
      cursor: grab;

      & .slick-slide .w-img {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        //visibility: hidden;

        & img {
          width: 100%;
          height: 100%;
          object-position: 50% 50%;
          object-fit: cover;
        }
      }

      & .slick-slider {
        height: 100%;

        & .slick-list {
          height: 100%;
        }

        & .slick-track {
          height: 100%;
        }
      }
    }

    & .slick-arrow {
      position: absolute;
      width: 35px;
      height: 35px;
      top: calc(50% - 35px);
      background: #fff;
      opacity: 0.5;
      border-radius: 0.15em;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }

      & i {
        display: inline-flex;
        align-items: center;
      }

      &.slick-prev {
        left: 50px;
        @media #{$max-lg} {
          left: 10px;
        }
      }

      &.slick-next {
        right: 50px;
        @media #{$max-lg} {
          right: 10px;
        }
      }
    }
  }
} // slider-one
