@use "../utils" as *;

/*----------------------------------------*/
/*  17. FOOTER CSS START
/*----------------------------------------*/
.tp-footer {
  &__widget {
    &-title {
      color: var(--tp-common-white);
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 22px;
      margin-bottom: 20px;
      opacity: 0.7;
      font-family: var(--tp-ff-poppins);
    }

    & ul {
      & li {
        margin-bottom: 5px;

        @media #{$max-lg} {
          margin-bottom: 0;
        }

        & span {
          list-style: none;
          font-size: 16px;
          color: var(--tp-common-white);
          line-height: 35.5px;
        }

        a {
          font-size: 14px;
          line-height: 32px;
          text-decoration: none;
          font-weight: 300;
          letter-spacing: 1.1px;
          color: var(--tp-common-white);
          font-family: var(--tp-ff-poppins);
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__2 {
    & ul {
      & li {
        & span {
          list-style: none;
          font-size: 16px;
          color: var(--tp-grey-5);
          line-height: 35.5px;
        }
        a {
          font-size: 16px;
          color: var(--tp-grey-5);
          line-height: 35.5px;
          &:hover {
            color: var(--tp-common-white);
          }
        }
      }
    }
  }

  &__top {
    padding: 60px 0;

    @media #{$max-lg} {
      padding: 30px 0;
    }

    &-title {
      font-size: 36px;
      color: var(--tp-common-white);

      @media #{$lg} {
        font-size: 30px;
      }

      @media #{$md} {
        font-size: 25px;
        margin-bottom: 30px;
      }

      @media #{$sm ,$xs} {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    & .tp-border-button {
      border-bottom: 1px solid #fff;
    }
  }

  &-border {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }

  &__main {
    & .tp-footer-border {
      padding: 60px 0 30px 0;

      @media #{$max-lg} {
        padding: 30px 0;
      }
    }
  }
} // tp-footer

.top-footer-copyright {
  padding: 30px 0;

  & .row {
    @media #{$max-lg} {
      flex-direction: column-reverse;
    }
  }
}

.tp-copyright-text {
  color: var(--tp-common-white);
  font-family: var(--tp-ff-poppins);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 22px;
  opacity: 0.7;

  @media #{$max-lg} {
    padding-top: 5px;
  }
}

.tp-footer-social-icon {
  & ul {
    & li {
      list-style: none;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;

      & a {
        font-size: 16px;
        color: var(--tp-common-white);
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }

    @media #{$max-lg} {
      padding-top: 15px;
    }
  }
}

.tp-ft-social-icons {
  ul {
    li {
      display: inline-block;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        & a {
          opacity: 0.8;
        }
      }
    }
  }
}

.footer-col-2-2 {
  padding-left: 50px;
  @media #{$md,$xs} {
    padding-left: 0;
  }
}
