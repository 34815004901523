@use "../utils" as *;

/*----------------------------------------*/
/*  12. PROMOTION CSS START
/*----------------------------------------*/

.tp-promotion-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-size: cover;
  @media #{$xs,$md} {
    display: none;
  }
}

.tp-promotion-bg-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  @media #{$xs,$md} {
    display: none;
  }
}

.tp-promotion-info {
  @media #{$xl} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media #{$lg} {
    padding-left: 60px;
    padding-right: 30px;
  }
  @media #{$md} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media #{$xs} {
    padding-left: 0;
    padding-bottom: 0;
  }
}

.tp-promotion-info-2 {
  // padding-right: 300px;
  @media #{$xl} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media #{$lg,$md} {
    padding-right: 30px;
    padding-left: 60px;
    & br {
      display: none;
    }
  }
  @media #{$xs} {
    padding: 60px 3vw;
  }

  @media #{$sm} {
    padding: 15% 15%;
  }
}

.tp-promotion-area {
  overflow: hidden;
}

.tp-progress-item {
  & h4 {
    font-family: var(--tp-ff-heading);
    font-size: 12px;
    text-transform: uppercase;
    line-height: 22px;
    letter-spacing: 0.18em;
  }

  & .progress {
    height: 3px;
  }

  & .progress-bar {
    background-color: var(--tp-common-black);
  }
}

.tp-progress-count {
  font-family: var(--tp-ff-heading);
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.18em;
}
